import React, {FC} from "react";
import {TextProps} from "./Text.types";
import clsx from "clsx";
import styles from "./Text.module.scss";

const Text: FC<TextProps> = (props) => {
    const {
        color,
        weight,
        componentTag,

        className,
        children,

        ...otherProps
    } = props;

    const rootStyles = clsx(
        styles.text,
        styles[`text_weight_${weight}`],
        styles[`text_color_${color}`],
        styles[`text_${componentTag}`],
    )
    const classes = clsx(rootStyles, className);
    const componentProps = {
        className: classes,
        ...otherProps
    }

    return React.createElement((componentTag as string), componentProps, children);
};

Text.defaultProps = {
    componentTag: "p"
}

export default Text;