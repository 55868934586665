import React from "react";
import Layout from "./components/Layout";
import {Route, Routes} from "react-router-dom";

const Home = React.lazy(() => import("./pages/Home/Home"));
const Author = React.lazy(() => import("./pages/Author/Author"));
const NotFound = React.lazy(() => import("./pages/NotFound/NotFound"));

function App() {
  return (
    <Layout>
        <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path={"/author"} element={<Author />} />
            <Route path={"*"} element={<NotFound />} />
        </Routes>
    </Layout>
  );
}

export default App;
