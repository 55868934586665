import React, {FC} from 'react';
import styles from "./Modal.module.scss";
import {ModalProps} from "./Modal.types";
import clsx from "clsx";

const Modal: FC<ModalProps> = (props) => {
    const {
        isOpen,

        className,
        children,
    } = props;

    const rootStyles = clsx(
        styles.modal,
        className,

        isOpen ? styles.modal_open : undefined
    );

    return (
        <div className={rootStyles}>
            <div className={styles.modal__container}>
                {children}
            </div>
        </div>
    );
};

export default Modal;