import React, {FC} from "react";
import styles from "./Button.module.scss";
import clsx from "clsx";
import {ButtonProps} from "./Button.types";

const Button: FC<ButtonProps> = (props) => {
    const {
        variant,
        color,
        size,

        href,
        disabled,
        className,

        children,
        ...otherProps
    } = props;

    const rootStyles = clsx(
        styles.button,
        styles[`button_${variant}`],
        styles[`button_color_${color}`],
        styles[`button_size_${size}`],
        disabled && [styles[`button_color_${color}_disabled`], styles.button_disabled],
    );
    const classes = clsx(rootStyles, className);

    const componentProps = {
        className: classes,
        href: href || undefined,
        disabled: disabled,

        ...otherProps
    }
    return React.createElement(componentProps.href ? "a" : "button", {...componentProps}, children);
};

Button.defaultProps = {
    variant: "fill",
    color: "blue",
    size: "medium",
}

export default Button;