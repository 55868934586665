import React, {FC} from "react";
import styles from "./Header.module.scss";
import clsx from "clsx";
import {Link} from "react-router-dom";
import buttonStyles from "../Button/Button.module.scss";

const Header: FC = () => {
    const rootLinkStyles = clsx(
        styles.menu__item,
        buttonStyles.button,
        buttonStyles.button_size_medium,
        buttonStyles.button_text,
        buttonStyles.button_color_blue,
    );
    return (
        <header className={styles.header}>
            <div className={styles.header__container}>
                <div className={clsx(styles.header__menu, styles.header__menu)}>
                    <Link to="/" className={rootLinkStyles}>
                        Home
                    </Link>
                    <Link to="/author" className={rootLinkStyles}>
                        Author
                    </Link>
                </div>
            </div>
        </header>
    );
};

export default Header;