import React, {FC, ReactNode} from "react";
import {Footer, Header, Main} from "./UI";

export interface LayoutProps {
    children: ReactNode;
}

const Layout: FC<LayoutProps> = (props) => {
    const {
        children
    } = props;
    return (
        <>
            <Header />
            <Main>
                <React.Suspense fallback={<h1>Loading...</h1>}>
                    {children}
                </React.Suspense>
            </Main>
            <Footer />
        </>
    );
};

export default Layout;