import React, {FC} from "react";
import {HeadlineProps, HeadlineSizeTag} from "./Headline.types";
import clsx from "clsx";
import styles from "./Headline.module.scss";

const Headline: FC<HeadlineProps> = (props) => {
    const {
        size,
        className,

        children,
        ...otherProps
    } = props;

    const rootStyles = clsx(
        styles.headline,
        styles[`headline_size_${size}`],
    )
    const classes = clsx(rootStyles, className);

    const componentProps = {
        className: classes,
        ...otherProps
    }
    return React.createElement(HeadlineSizeTag[size || "rg"], componentProps, children);
};

Headline.defaultProps = {
    size: "rg"
}

export default Headline;