import React from 'react';
import styles from "./Footer.module.scss";

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer__container}>
                &copy; 2021-2022 S3UI
            </div>
        </footer>
    );
};

export default Footer;