import React, {FC} from 'react';
import {MainProps} from "./Main.types";
import styles from "./Main.module.scss";
import clsx from "clsx";

const Main: FC<MainProps> = (props) => {
    const {
        className,
        children,

        ...otherProps
    } = props;
    const rootStyles = clsx(
        styles.main,
        className
    );
    return (
        <main
            className={rootStyles}
            {...otherProps}
        >
            {children}
        </main>
    );
};

export default Main;