import {HTMLAttributes, ReactNode} from "react";

export const HeadlineSizeTag = {
    xl: "h1",
    lg: "h2",
    rg: "h3",
    sm: "h4",
    xs: "h5",
    xxs: "h6"
}

export interface HeadlineProps extends HTMLAttributes<HTMLHeadingElement> {
    size?: keyof typeof HeadlineSizeTag;

    children?: ReactNode;
}